import React from 'react';
import './Hamburger.scss';

const Hamburger = ({ isMenuOpened }) => {
    const hamburgerClasses = isMenuOpened ? 'hamburger hamburger--opened' : 'hamburger';

    return (
        <span className={hamburgerClasses}></span>
    );
};

export default Hamburger;