import React from "react"
import PropTypes from "prop-types"

import Nav from "./Nav/Nav";
import Heading from "./Heading/Heading";
import SEO from "./seo";

import "../assets/scss/_global.scss";
import "./layout.scss";

const Layout = ({ pageTitle, children }) => (
    <>
        <SEO title={pageTitle} />
        <Nav />
        <header>
            <Heading title={pageTitle} />
        </header>
        <main className="mainWrapper">
            {children}
        </main>
        <footer className="footer">
            <p className="footer__paragraph">&copy; All rights reserved | {new Date().getFullYear()} Piotr Kawula.</p>
        </footer>
    </>
);

Layout.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Layout;
