import React from 'react';
import PropTypes from 'prop-types';

import './ButtonIcon.scss';

const ButtonIcon = ({ href, children, className, handleUserClick, ariaLabel, disabled }) => (
    <>
        {href ?
            <a href={href} className={`button button__link ${className}`} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel} >{children}</a>
            :
            <button disabled={disabled} onClick={handleUserClick} className={`button button__primary ${className}`} aria-label={ariaLabel}>{children}</button>
        }
    </>
);

ButtonIcon.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    handleUserClick: PropTypes.func,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
}

ButtonIcon.defaultProps = {
    href: '',
    handleUserClick: null,
    className: '',
    ariaLabel: '',
    disabled: false
}

export default ButtonIcon;