import React from 'react';
import propTypes from 'prop-types';
import './Heading.scss';

const Heading = ({ title }) => {

    const text = title.split(' ');
    const firstWord = text[0];
    const otherWords = text.filter(word => word !== firstWord).join(' ');

    return <h1 className="heading"><span className="heading__firstWord">{firstWord}</span> {otherWords}</h1>
};

Heading.propTypes = {
    title: propTypes.string.isRequired
}

export default Heading;