import React, { useState } from 'react';
import { Link } from 'gatsby';
import LogoDark from '../../assets/images/logo_dark.png';
import LogoLight from '../../assets/images/logo_light.png';
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import Hamburger from "./Hamburger";

import "./Nav.scss";
import { Helmet } from 'react-helmet';

const Nav = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const toggleMenuState = () => setIsMenuOpened(!isMenuOpened);

    const Logo = isMenuOpened ? LogoLight : LogoDark;
    const navClasses = isMenuOpened ? 'nav nav__menuOpened' : 'nav';
    const mobileMenuClasses = isMenuOpened ? 'nav__list nav__list--opened' : 'nav__list';
    const buttonClasses = isMenuOpened ? 'button--menu button--menuOpened' : 'button--menu';

    return (
        <>
            {isMenuOpened &&
                <Helmet>
                    <body className="hideoverflow" />
                </Helmet>
            }
            <nav className={navClasses} aria-label="navigation" role="navigation">
                <Link className="nav__logo--link" to="/">
                    <img className="nav__logo--image" src={Logo} alt="Logo" />
                    piotrkawula.pl
                </Link>
                <ButtonIcon className={buttonClasses} handleUserClick={toggleMenuState}><Hamburger isMenuOpened={isMenuOpened} /></ButtonIcon>
                <ul className={mobileMenuClasses}>
                    <li className="nav__list--item">
                        <Link className="nav__list--link" to="/skills">My Skills</Link>
                    </li>
                    <li className="nav__list--item">
                        <Link className="nav__list--link" to="/projects">My Projects</Link>
                    </li>
                    <li className="nav__list--item">
                        <Link className="nav__list--link" to="/contact">Contact Me</Link>
                    </li>
                </ul>
            </nav>
        </>
    )
};

export default Nav;